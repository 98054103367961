

.navbar-fixed {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 10000 !important;
    background-color:white !important;
    margin:0px !important;
    padding:0px !important;
    /* box-shadow: 2px 2px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    transition: all 0.2s ease-in-out !important;

  }